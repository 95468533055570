<template>
  <div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadData"></head-component>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >
    <template slot="header">
        <div class="lg:w-auto w-full md:mt-0 lg:flex grid grid-cols-3 gap-2 items-center">
          <vs-button v-if="$route.query.filter !== 'completed'" :disabled="!selectedFlag" @click="updateOrdersStatus()" class="float-left whitespace-nowrap font-normal">
            <p>{{$t('Confirm')}}</p>
          </vs-button>
        </div>
      </template>

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Full Name')}}</shipblu-th>
        <shipblu-th>{{$t('Phone')}}</shipblu-th> 
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Address Line 1')}}</shipblu-th>
        <shipblu-th>{{$t('Address Line 2')}}</shipblu-th>
        <shipblu-th>{{$t('Governorate')}}</shipblu-th>
        <shipblu-th>{{$t('City')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Notes')}}</shipblu-th>
        <shipblu-th>{{$t('Confirm')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr class="rezoning-tr" :unHover="true" :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td :data="data[indextr].tracking_number" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].full_name">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>
          
          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <template>
              <div class="flex" style="width: fit-content;">
                <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
            </template>
          </shipblu-td>

          <shipblu-td  :data="data[indextr].addressLine">
            <div v-if="data[indextr].line1Edit === false" class="flex items-center justify-between">
              {{ data[indextr].customer.address.line_1 }}
              <feather-icon @click="data[indextr].line1Edit = true, data[indextr].line1Value = data[indextr].customer.address.line_1" icon="EditIcon" class="w-4 h-4"></feather-icon>
            </div>
            <div v-else class="flex w-full">
              <vs-input v-model="data[indextr].line1Value" v-on:keyup.enter.prevent="removeAttribute($event)" class="mr-1"/>
              <vs-button icon-pack="feather" icon="icon-check" @click="data[indextr].line1Value ? updateOrder('line_1', data[indextr], data[indextr].line1Value): ''"></vs-button>
              <vs-button icon-pack="feather" @click="data[indextr].line1Edit = false" icon="icon-x" class="ml-1" ></vs-button>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].addressLine">
            <div v-if="data[indextr].line2Edit === false" class="flex items-center justify-between">
              {{ data[indextr].customer.address.line_2 }}
              <feather-icon @click="data[indextr].line2Edit = true, data[indextr].line2Value = data[indextr].customer.address.line_2" icon="EditIcon" class="w-4 h-4"></feather-icon>
            </div>
            <div v-else class="flex w-full">
              <vs-input v-model="data[indextr].line2Value" v-on:keyup.enter.prevent="removeAttribute($event)" class="mr-1"/>
              <vs-button icon-pack="feather" icon="icon-check" @click="data[indextr].line2Value ? updateOrder('line_2', data[indextr], data[indextr].line2Value) : ''"></vs-button>   
              <vs-button icon-pack="feather" @click="data[indextr].line2Edit = false" icon="icon-x" class="ml-1" ></vs-button>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].governorate">
            <div class="flex justify-between select-gov h-full gap-2">
              <v-select class="address" name="governorate"
                v-model="data[indextr].customerGov" v-on:input="data[indextr].customerGov ? loadCities(data[indextr], data[indextr].customerGov.id) : data[indextr].cities = []" :options="governorates" label="name"/>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].City">
            <div class="flex justify-between select-gov h-full gap-2" @click="data[indextr].customerGov ? loadCities(data[indextr], data[indextr].customerGov.id) : data[indextr].cities = []">
              <v-select class="address" name="city"
                v-model="data[indextr].customerCity" v-on:input="data[indextr].customerCity ? loadZones(data[indextr], data[indextr].customerCity.id) : data[indextr].zones = []" :options="data[indextr].cities" label="name" />
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].Zone">
            <div class="flex justify-between select-gov h-full gap-2" @click="data[indextr].customerCity ? loadZones(data[indextr], data[indextr].customerCity.id) : data[indextr].zones = []">
              <v-select class="address" name="zone" v-on:input="data[indextr].customer.address.zone ? updateOrder('zone', data[indextr], data[indextr].customer.address.zone) : ''"
                v-model="data[indextr].customer.address.zone" :options="data[indextr].zones" label="name"/>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].Note">
            <div v-if="data[indextr].status === 'delivery_attempted' && data[indextr].tracking_events.filter(element => element.status === 'delivery_attempted' && element.comment && (element.comment.includes('Address') || element.comment.includes('address'))).length > 0">
              <vx-tooltip :text="data[indextr].tracking_events.map(element => element.status === 'delivery_attempted' && element.comment && (element.comment.includes('Address') || element.comment.includes('address')) ? element.comment  : '').filter(element => element !== '').join('\n \n')">
                <feather-icon icon="AlertCircleIcon" class="w-8 h-8"></feather-icon>
              </vx-tooltip>
            </div>
          </shipblu-td>

          <shipblu-td :data="data[indextr].confirm">
            <vs-button v-if="data[indextr].customer.address.zone_verified === null" @click="updateOrder('status', data[indextr], 'completed')" class="ml-1 font-semibold bg-confirm">
              <p>{{$t('Confirm')}}</p>
            </vs-button>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import vSelect from 'vue-select'
import HeadComponent from '../merchant/components/Header.vue'
import i18nData from '../../i18n/i18nData.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      filters: '',
      statusFilters: [
        {
          name: 'Pending',
          value: 'pending'
        },
        {
          name: 'Completed',
          value: 'completed'
        }
      ],
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      tableLoader: false,
      filter: '',
      searchVal: '',
      offset: 0,
      shipment: {},
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchInProgress: false,
      searchedValue: ' ',
      governorates: [],
      confirmOrder: false,
      selectedFlag: false
    }
  },
  watch: {
    currentPage () {
      this.selected = []
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    '$route.params.warehouseID' () {
      this.selected = []
      this.searchVal = ''
      this.currentPage = 1
      this.filters = 'pending'
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    confirmOrder (val) {
      if (val) {
        this.updateRezoningData()
      } 
    },
    selected (val) {
      if (val.length !== 0 && val.length > 1 && val.filter(item => item.status === 'completed').length === 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    },
    '$route.query.filter' () {
      this.selected = []
      this.currentPage = 1
    }
  },
  methods: {
    updateOrdersStatus () {
      const customerIDs = {
        address_ids: []
      }
      this.selected.forEach(element => {
        customerIDs.address_ids.push(element.customer.address.id)
      })
      sendRequest(true, false, this, 'api/v1/warehousing/rezoning/bulk-address-confirm/', 'post', customerIDs, true,
        (response) => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text: response.data.message,
            position: 'top-center'
          })
          this.selected.forEach(order => {
            this.shipments.forEach((item, index) => {
              if (item.id === order.id) {
                this.shipments.splice(index, 1)
              }
            })
          })
          this.selected = []
        })
    },
    changeFilter () {
      this.filters = this.filterValue
      this.searchVal = ''
      this.currentPage = 1
      this.$router.push({
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadData()
    },
    removeAttribute (event) {
      event.stopPropagation()
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadData()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `api/v1/warehousing/?warehouse_code=${this.$route.params.warehouseID}&rezoning_status=${this.filters}&${query}`, 'get', null, true,
        (response) => {
          response.data.results.forEach(element => {
            element.customerGov = element.customer.address.zone.city.governorate
            element.customerCity = element.customer.address.zone.city
            element.line1Edit = false
            element.line2Edit = false
            element.cities = []
            element.zones = []
          })
          this.shipments = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    updateOrder (field, order, value) {
      let orderObj = {}
      switch (field) {
      case 'line_1':
        orderObj = {
          customer: {
            id: order.customer.id,
            address: {
              id: order.customer.address.id,
              line_1: value
            }
          }
        }
        break
      case 'line_2':
        orderObj = {
          customer: {
            id: order.customer.id,
            address: {
              id: order.customer.address.id,
              line_2: value
            }
          }
        }
        break
      case 'zone':
        orderObj = {
          customer: {
            id: order.customer.id,
            address: {
              id: order.customer.address.id,
              zone: value.id
            }
          }
        }
        break
      case 'status':
        orderObj = {
          customer: {
            id: order.customer.id,
            address: {
              id: order.customer.address.id,
              zone_verified: new Date()
            }
          }
        }
        break
      }
      order.line1Edit = false
      order.line2Edit = false
      sendRequest(true, false, this, `api/v1/${order.tracking_number[0] === '2' || (order.tracking_number[0] === '3' && order.tracking_number[1] === '2') ? 'returns' : order.tracking_number[0] === '4' ? 'cash-collections' : 'delivery-orders'}/${order.id}/`, 'patch', orderObj, true,
        (response) => {
          this.shipments.forEach((item, index) => {
            if (item.id === order.id) {
              item.customer.address.line_1 = response.data.customer.address.line_1
              item.customer.address.line_2 = response.data.customer.address.line_2
              if (field === 'status') {
                this.shipments.splice(index, 1)
              }
            }
          })
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:'Your order has been confirmed successfully!',
            position: 'top-center'
          })
        }
      )
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
        this.governorates = this.governorates.filter(item => item.id !== 20)
      })
    },
    loadCities (order, governorateID) {
      const cityPromise = common.loadCities(governorateID, this)
      cityPromise.then((results) => {
        order.cities = results.data
      })
    },
    loadZones (order, cityID) {
      const zonePromise = common.loadZones(cityID, this)
      zonePromise.then((results) => {
        order.zones = results.data.filter(item => item.id !== 284)
      })
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    vSelect,
    HeadComponent,
    ShipbluPagination
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? this.$route.query.filter : this.$route.query.filter : 'pending'
    this.$router.push({
      query: {
        page: this.currentPage,
        filter: this.filters
      }
    }).catch(() => {})
    this.loadData()
    this.loadGovernorates()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
.rezoning-tr {
  background: white;
}
.rezoning-tr td {
  border-bottom: 1px solid #E5E5E5 !important;
  padding: 16px 12px !important;
}
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.bg-confirm {
  background: #17814C !important;
}
.con-pagination-table{
  display: none;
}
.select-gov {
  .v-select {
    &.address {
      width: 100%;
      min-width: 150px;
      .vs__selected {
        width: 90px;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    background: #fff;
    border-radius: 6px;
    position: relative;
    &.order-type {
      .vs__selected {
        color: #0A3266;
        white-space: nowrap;
      }
      .vs__dropdown-toggle {
        border: none;
      }
    }
    .vs__selected {
      color: #1C5BFE;
      padding: 0px;
    }
    .vs__actions {
      padding: 4px 2px 0px 3px;
      .vs__clear {
        margin-right: 0px;
      }
    }
    .vs__dropdown-toggle {
      border: 1px solid #C9C9C9;
      border-radius: 6px;
      height: 100%;
      min-width: 100px;
      .vs__selected-options {
        input.vs__search {
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      }
    }
    .feather-chevron-down, .feather-x {
      fill: #E5E5E5;
      stroke: #E5E5E5;
    }
    ul.vs__dropdown-menu {
      z-index: 9999999999 !important;
      top: calc(100% - 25px);
    }
    ul {
      border: none;
      z-index: 2;
      margin-top: 0px;
      overflow-x: hidden;
      width: fit-content;
      li {
        padding: 3px 6px;
      }
    }
  }
}
</style>